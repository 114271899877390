export enum EUserRoles {
  SUPER_ADMIN = 'Тест',
  ADMIN  = 'Администратор',
  MANAGER = 'Менеджер',
  CUSTOMER = 'Заказчик',
  WORKER = 'Исполнитель',
}

export type TUserRolesKeys = keyof typeof EUserRoles

export type TUser = {
  id: string;
  email: string;
  firstName: string;
  secondName: string;
  phoneNumber: string;
  city: string;
  role: TUserRolesKeys;
}
export type TUserTable = {
  id: string;
  email: string;
  firstName: string;
  secondName: string;
  phoneNumber: string;
  comission:string;
  city: string;
  tag:string;
  role: TUserRolesKeys;
}

export type TUserFilters = {
  page: number;
  pageSize: number;
  total: number;
  search: string;
  roles: TUserRolesKeys[];
}

export type TUserData = {
  id?: string;
  email: string;
  firstName: string;
  secondName: string;
  phoneNumber: string;
  city: string;
  role: TUserRolesKeys;
}