import { LoginBox, Logo, Wrapper } from "./styles";
import logo from "../../core/assets/images/logo.png";
import  {LoginForm}  from "../../components/forms/LoginForm";

 const LoginPage = () => {
  return (
    <Wrapper>
      <LoginBox>
        <Logo>
          <img src={logo} alt="" />
        </Logo>
        <LoginForm />
      </LoginBox>
    </Wrapper>
  );
}

export default LoginPage;
