import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorRequestStatus, loadingRequestStatus, successRequestStatus } from '../../core/constants/common';
import { TVehicleFilters, TVehicleData, TRequestStatus, TVehicle } from '../../core/types';
import { createVehicle, getVehicle, getVehicleList, updateVehicle } from '../../services/vehicle';



interface IInitialState {
  status: TRequestStatus;
  list: TVehicleData[];
  filters: TVehicleFilters;
  target: TVehicle | null;
}


const initialState: IInitialState = {  
  status: {
    loading: false,
    success: true,
    error: false,
  },
  filters: {
    page: 1,
    pageSize: 5,
    total: 0,
  },
  list: [
    {
      id: Math.random().toString(),
      name: "clamshell",
      displayName: "Грейфер",
    },
    {
      id: Math.random().toString(),
      name: "bulldozer",
      displayName: "Бульдозер",
    },
    {
      id: Math.random().toString(),
      name: "excavator",
      displayName: "Экскаватор",
    },
    {
      id: Math.random().toString(),
      name: "boer",
      displayName: "Ямобур",
    },
    {
      id: Math.random().toString(),
      name: "tractor",
      displayName: "Трактор",
    },
  ],
  target: null,
};

export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        setList: (state, action: PayloadAction<TVehicle[]>) => {
            state.list = action.payload
          },
        },
        extraReducers: (builder) => {
          builder.addCase(getVehicleList.fulfilled, (state, action) => {
            state.list = action.payload.list;
            state.filters.total = action.payload.pagination.total;
            state.status = successRequestStatus;
          });
          builder.addCase(getVehicleList.pending, (state) => {
            state.status = loadingRequestStatus;
          });
          builder.addCase(getVehicleList.rejected, (state) => {
            state.status = errorRequestStatus;
          });
          builder.addCase(getVehicle.fulfilled, (state, action) => {
            state.target = action.payload;
            state.status = successRequestStatus;
          });
          builder.addCase(getVehicle.pending, (state) => {
            state.status = loadingRequestStatus;
          });
          builder.addCase(getVehicle.rejected, (state) => {
            state.target = null;
            state.status = errorRequestStatus;
          });
          builder.addCase(createVehicle.fulfilled, (state) => {
            state.status = successRequestStatus;
            state.filters = { ...state.filters };
            state.target = null;
          });
          builder.addCase(createVehicle.pending, (state) => {
            state.status = loadingRequestStatus;
          });
          builder.addCase(createVehicle.rejected, (state) => {
            state.status = errorRequestStatus;
          });
          builder.addCase(updateVehicle.fulfilled, (state) => {
            state.status = successRequestStatus;
            state.filters = { ...state.filters };
            state.target = null;
          });
          builder.addCase(updateVehicle.pending, (state) => {
            state.status = loadingRequestStatus;
          });
          builder.addCase(updateVehicle.rejected, (state) => {
            state.status = errorRequestStatus;
          });
        }
      });


export const { setList } = vehicleSlice.actions;

export default vehicleSlice.reducer;