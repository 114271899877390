import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #e9ebf7;
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const LoginBox = styled.div`
  margin: auto;
  width: 600px;
  /* height: 500px; */
  height: fit-content;
  background-color: white;
  border-radius: 20px;
  padding: 82px 100px;

  @media only screen and (max-width: 650px) {
    width: 90%;
  }
  @media only screen and (max-width: 576px) {
    padding: 40px 50px;
  }
`;

export const Logo = styled.div`
  width: fit-content;
  margin: 0 auto 40px;
  img {
    display: block;
  }
`;

export const CheckboxRow = styled.div`
  display: flex;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 24px;
  label {
    display: flex;
    align-items: center;
    input {
      width: 16px;
      height: 16px;
      margin: 0 8px 0 0;
    }
  }
`;
