import { TUser } from './user.types'

export interface ITableProps {
  data: TUser[];
}

export interface IVehicleTableProps {
  data: {
    name: string;
    inn: string;
    vehicle: string;
    parameter: string;
  }
}
export type TVehicle = {
  id: string,
  name: string,
  displayName: string,
}

export interface ITableRowProps {
  vehicle: TVehicle;
}

export enum FilterType {
  Asc = "asc",
  Desc = "desc",
  Unset = "unset",
}

export enum FilterColumn {
  Id = "id",
  Name = "name",
  Phone = "phone",
  City = "city",
  Status = "status",
  Commission = "commission",
  None = "none",
}

export interface Filter {
  filterType: FilterType;
  filterColumn: FilterColumn;
}

// ---------------

export type TChangePaginationProps = {
  page: number;
  pageSize: number;
}
