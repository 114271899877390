import React, { memo, useState } from "react";
import { PageWrapper } from "../../components/pageWrapper/styles";
import { PageHeader } from "../../components/pageHeader/styles";
import { AddButton, ExportButton } from "../../components/@UI/Button/Button";
import Modal from "../../components/modal";
import { UsersTable } from "../../components/tables/UsersTable";
import { TableFilter } from "../../components/filters/TableFilter";
import { useNavigate, useParams } from "react-router";
import { EPages } from "../../core/types";
import { BadgeWrapper, TableBadge, TableElement, TableWrapper } from "../../components/tableComponent/index";
import PageMenu from "../../components/pageMenu";

const UsersPage: React.FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  return (
    <>
      <PageWrapper>
        <PageHeader>
          <div className={"pageTitle"}>Пользователи</div>
          <div className={"buttons"}>
            <ExportButton isOpen={dropdownOpen} onClick={setDropdownOpen} />
            <AddButton onClick={() => navigate(`${EPages.USERS_PAGE}/create`)} />
          </div>
        </PageHeader>
        <TableWrapper>
          <BadgeWrapper>
            <TableBadge 
              count={983} 
              overflowCount={999}
            >
              <TableElement>Все</TableElement>
            </TableBadge>
            <TableBadge 
              count={128} 
              overflowCount={999} 
            >
              <TableElement>Заказчики</TableElement>
            </TableBadge>
            <TableBadge 
              count={15} 
              overflowCount={999} 
            >
              <TableElement>Испослители</TableElement>
            </TableBadge>
            <TableBadge 
              count={8} 
              overflowCount={999} 
            >
              <TableElement>Агенты</TableElement>
            </TableBadge>
          </BadgeWrapper>
          <TableFilter />
          <UsersTable />
        </TableWrapper>
      </PageWrapper>
      {userId && <Modal />}
    </>
  );
};

export default memo(UsersPage);
