import React, { memo, useCallback, useEffect } from "react";
import "./App.css";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import MainPage from "./pages/mainPage";
import { useLocation } from "react-router-dom";
import { EPages } from "./core/types";
import { getProfile } from "./services/auth";
import { useAppDispatch, useAppSelector } from "./storage/store";
import { ConfigProvider } from "antd";

function App() {
  const { pathname } = useLocation();

  const isLoginPage = pathname === EPages.LOGIN_PAGE;
  
  const dispatch = useAppDispatch();

  const isLoadingAuth = useAppSelector((state) => state.auth.status.loading);
  const profile = useAppSelector((state) => state.auth.profile);

  const handleCheckAuthorization = useCallback(async () => {
    if (pathname !== EPages.LOGIN_PAGE && !profile && !isLoadingAuth) {
      await dispatch(getProfile());
    }
  }, [dispatch, isLoadingAuth, pathname, profile])

  useEffect(() => {
    handleCheckAuthorization();
  }, [handleCheckAuthorization])

  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#FF6632',
      },
    }}
  >
    <div className={"container"}>
      {(!isLoadingAuth || isLoginPage) && (
        <>
          {!isLoginPage && <Sidebar />}
          <div className={"contentWrapper"}>
            {!isLoginPage && <Header />}
            <MainPage />
          </div>
        </>
      )}
    </div>
  </ConfigProvider>
  );
}

export default memo(App);
