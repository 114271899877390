import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.61);
`;

export const ModalWindow = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  max-height: 868px;
  background: white;
  border-radius: 20px;
  padding: 30px;
`;

export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

