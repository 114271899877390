import {createAsyncThunk} from '@reduxjs/toolkit';
import {request} from "../core/helpers";
import { TVehicle, TVehicleFilters, TVehicleData } from "../core/types";

type TGetVehicleList = { list: TVehicle[], pagination: TVehicleFilters };

export const getVehicleList = createAsyncThunk<TGetVehicleList, TVehicleFilters>(
  'vehicle/list',
  async (payload) => {
    const response = await request.post('/vehicle/list', payload);

    return response.data;
  }
);

export const getVehicle = createAsyncThunk<TVehicle, string>(
  'vehicle/get',
  async (payload) => {
    const response = await request.get(`/vehicle/${payload}`);

    return response.data;
  }
);

export const createVehicle = createAsyncThunk<TVehicle, TVehicleData>(
  'vehicle/create',
  async (payload) => {
    const response = await request.post(`/vehicle/create`, payload);

    return response.data;
  }
);

export const updateVehicle = createAsyncThunk<TVehicle, TVehicleData>(
  'vehicle/update',
  async ({ id, ...payload }) => {
    const response = await request.patch(`/vehicle/${id}`, payload);

    return response.data;
  }
);