import * as yup from "yup";

export enum ELoginFormFields {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export const defaultValues = {
  [ELoginFormFields.EMAIL]: '',
  [ELoginFormFields.PASSWORD]: '',
}

export const schema = yup.object().shape({
  [ELoginFormFields.EMAIL]: yup.string().email().required(),
  [ELoginFormFields.PASSWORD]: yup.string().max(20).min(6).required(),
}).required();