import React from "react";
import { Navigate, Route, Routes } from "react-router";
import VehiclesPage from "../vehiclesPage";
import LoginPage from "../loginPage";
import { EPages } from "../../core/types";
import UsersPage from "../usersPage";

const MainPage = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Navigate to={EPages.USERS_PAGE} />} />
      <Route path={EPages.LOGIN_PAGE} element={<LoginPage />} />
      <Route path={EPages.USERS_PAGE} element={<UsersPage />} />
      <Route path={`${EPages.USERS_PAGE}/:userId`} element={<UsersPage />} />
      <Route path={EPages.VEHICLES_PAGE} element={<VehiclesPage />} />
      <Route path={`${EPages.VEHICLES_PAGE}/:vehicleId`} element={<VehiclesPage />} />
      <Route path={EPages.VEHICLES_ADD_PAGE} element={<></>} />
    </Routes>
  );
};

export default MainPage;
