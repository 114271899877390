import { Select, Typography } from "antd"
import { BaseOptionType, DefaultOptionType, SelectProps } from "antd/es/select";
import { FC } from "react";
import { Column } from "../Column"

interface ISingleSelectProps {
  label: string;
  options: any;
}

export const SingleSelect: FC<ISingleSelectProps> = <ValueType = any, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>({
  label,
  ...props
}:  SelectProps<ValueType, OptionType> & ISingleSelectProps) => {
  return (
    <Column size="small">
        <Typography.Text>{label}</Typography.Text>
        <Select
          {...props}
          style={{ width: '100%' }}
          size="large"
          allowClear
        />
    </Column>
  )
}