export enum EPages {
  LOGIN_PAGE = '/login',
  USERS_PAGE = '/users',
  USER_PAGE = '/users/:userId',
  VEHICLES_PAGE = '/vehicles',
  VEHICLES_ADD_PAGE = '/vehicles/add',
}

export enum EStatus {
  SUCCESS = 'success',
  LOADING = 'loading',
  ERROR = 'error',
}

export type TRequestStatus = Record<EStatus, boolean>;