import styled from "styled-components";
import { Input, Select } from "antd";



export const CommonInput = styled(Input)`
    width: 70%;
    border: 1px solid #d9d9d9;
    font-size: 15px;
`

export const CommonSelect = styled(Select)`
    width: 30%;
    color: #3f434a;
`

