import {createAsyncThunk} from '@reduxjs/toolkit';
import {request} from "../core/helpers";
import { TLoginData, TUser } from "../core/types";

export const login = createAsyncThunk<TUser, TLoginData>(
  'auth/login',
  async (payload) => {
    const response = await request.post('/auth/login', payload);

    return response.data;
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async () => {
    const response = await request.get('/auth/logout');

    return response.data;
  }
);

export const getProfile = createAsyncThunk<TUser>(
  'auth/profile',
  async () => {
    const response = await request.get('/auth/profile');

    return response.data;
  }
);
