import { Table } from "antd";
import { memo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { pageSizeOptions, tableLocale } from "../../../core/constants/table";
import { getVehicleList } from "../../../services/vehicle";
import { setFilters } from "../../../storage/models/users";
import { useAppDispatch, useAppSelector } from "../../../storage/store";
import { columns } from './columns';


const VehiclesTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const vehicles = useAppSelector((state) => state.vehicles.list);
  const filters = useAppSelector((state) => state.vehicles.filters);

  const handleChangePagination = useCallback(
    (page: number, pageSize: number) => {      
        dispatch(setFilters({ ...filters, page, pageSize }));
    },
    [dispatch, filters],
    );

    useEffect(
    () => {
        dispatch(getVehicleList(filters));
    },
    [dispatch, filters],
    );

 return (
    <Table
        rowKey="id"
        dataSource={vehicles}
        columns={columns(navigate)}
        pagination={{
            showSizeChanger: true,
            pageSizeOptions,
            locale: tableLocale,
            onChange: handleChangePagination,
            }}
    />
 )
};

export default memo(VehiclesTable);
