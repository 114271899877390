import styled from "styled-components";

export const Button = styled.button`
  width: 100%;
  background: #FF6632;
  border: 0;
  color: white;
  padding: 10px;
  border-radius: 14px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  &:hover {
    opacity: 0.95;
  }
`;

export const AddButtonStyled = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FF6632;
  border-radius: 14px;
  padding: 10px 14px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  
  &:active {
    transform: translateY(2px);
    box-shadow: none;
  }
`;

export const ButtonSplitted = styled.div`
  height: 40px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FF6632;
  border-radius: 14px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0px 8px 16px rgba(48, 76, 253, 0.2));  
  }
  
  .buttonText {
    padding: 10px 24px;
  }

  .buttonIcon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonExport = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 14px;
  padding: 10px 14px;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  color: #3F434A;
  
  svg {
    margin-right: 6px;
  }
  
  .dropdown {
    position: absolute;
    display: none;
    flex-direction: column;
    background: #ffffff;
    top: 50px;
    left: 0;
    width: 190px;
    border-radius: 14px;
    padding: 22px 12px;
    animation: growDown 0.3s ease;
    box-shadow: 0 20px 50px 0 rgba(44, 63, 88, 0.15);
    
    .dropdown__item {
      display: flex;
      padding: 10px 14px;
      border-radius: 14px;
      font-size: 15px;
      color: #595F69;
      
      &:hover {
        background: #F8F8F8;
        color: #3F434A;
      }
    }
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0)
    }
    100% {
      transform: scaleY(1)
    }
  }
`;

export const ButtonEmpty = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #595F69;
  border-radius: 10px;
  padding: 9px 15px;
  cursor: pointer;
  width: 155px;
  margin-bottom: 24px;
`;