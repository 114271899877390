import styled from "styled-components";

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 270px;
  border-right: 1px solid #E8E9EB;
  background: #ffffff;
  overflow-y: auto;
  
  img {
    margin-top: 20px;
    margin-left: 30px;
    height: 26px;
    width: 80px;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: #3F434A;
  
  a { 
    text-decoration: none;
    color: inherit;
  }
`;

interface IMenuItemProps {
  active?: boolean;
}

export const MenuItem = styled.div<IMenuItemProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 18px 28px;
  height: 56px;
  max-height: fit-content;
  cursor: pointer;
  position: relative;
  ${props => props.active && "background: #f8f8f8;"}
  
  svg {
    margin-right: 13px;
  }
  
  .name {
    font-family: "Golos", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: #3F434A;
    text-decoration: none;
  }
  
  &:hover {
    background: #F8F8F8;
  }
`;

export const ChildrenMenu = styled.div<IMenuItemProps>`
  display: flex;
  flex-direction: column;
  animation: growDown 0.3s ease-in;
  
  @keyframes growDown {
    0% {
      transform: scaleY(0)
    }
    80% {
      transform: scaleY(1.1)
    }
    100% {
      transform: scaleY(1)
    }
  }
`;