import { yupResolver } from "@hookform/resolvers/yup";
import { memo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { EPages, EUserRoles, TUserData } from "../../../core/types";
import { createUser, updateUser } from "../../../services/users";
import { useAppDispatch, useAppSelector } from "../../../storage/store";
import { Button } from "../../@UI/Button";
import { Column } from "../../@UI/Column";
import { TextInput } from "../../@UI/Input";
import { Row } from "../../@UI/Row";
import { SingleSelect } from "../../@UI/Select/SingleSelect";
import { EUsersFormFields, schema } from "./schema";


const userRolesOptions = Object.entries(EUserRoles).map(([value, label]) => ({
  label,
  value,
}));

const UsersForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.users.target);
  const isCreate = !user?.id;

  const { handleSubmit, control, reset } = useForm<TUserData>({
    resolver: yupResolver(schema)
  });

  const handleSubmitForm = async (data: TUserData) => {
    if (isCreate) {
      await dispatch(createUser(data));
    } else {
      await dispatch(updateUser({ id: user?.id, ...data }));
    }

    navigate(EPages.USERS_PAGE)
  }

  useEffect(
    () => {
      if (!isCreate) {
        reset(user);
      }
    },
    [isCreate, reset, user],
  );
  
  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Column>
        <Row>
          <Controller
            name={EUsersFormFields.FIRST_NAME}
            control={control}
            render={({ field }) => <TextInput {...field} label={"Имя"} />}
          />
          <Controller
            name={EUsersFormFields.SECOND_NAME}
            control={control}
            render={({ field }) => <TextInput {...field} label={"Фамилия"} />}
          />
        </Row>
        <Row>
          <Controller
            name={EUsersFormFields.EMAIL}
            control={control}
            render={({ field }) => <TextInput {...field} label={"Email"} />}
          />
          <Controller
            name={EUsersFormFields.PHONE_NUMBER}
            control={control}
            render={({ field }) => <TextInput {...field} label={"Телефон"} />}
          />
        </Row>
        <Row>
          <Controller
            name={EUsersFormFields.CITY}
            control={control}
            render={({ field }) => <TextInput {...field} label={"Город"} />}
          />
          <Controller
            name={EUsersFormFields.ROLE}
            control={control}
            render={({ field }) => <SingleSelect {...field} label={"Роль"} options={userRolesOptions} />}
          />
        </Row>
        <Button type="submit" style={{ marginTop: 20 }}>Сохранить</Button>
      </Column>
    </form>
  )
}

export default memo(UsersForm);