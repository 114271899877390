import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { NavigateFunction } from "react-router";
import { EPages } from "../../../core/types";
import { TVehicleData } from "../../../core/types/vehicle.types";

export const columns: (navigate: NavigateFunction) => ColumnsType<TVehicleData> = (navigate) => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Имя',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Имя*',
    dataIndex: 'displayName',
    key: 'vehicleName',
  },

  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (id: string) => (
      <Button
        type="primary"
        icon={<EditOutlined />}
        onClick={() => navigate(`${EPages.VEHICLES_PAGE}/${id}`)}
      />
    ),
  },
];