import React, { memo } from "react";
import { Menu, MenuItem, SidebarWrapper } from "./styles";
import { ReactComponent as GridIcon } from "../../core/assets/icons/grid.svg";
import { ReactComponent as CartIcon } from "../../core/assets/icons/cart.svg";
import { ReactComponent as LockIcon } from "../../core/assets/icons/lock.svg";
import { ReactComponent as PdfIcon } from "../../core/assets/icons/pdf.svg";
import { ReactComponent as PhoneIcon } from "../../core/assets/icons/phone.svg";
import { ReactComponent as UserIcon } from "../../core/assets/icons/user.svg";
import { ReactComponent as VehicleIcon } from "../../core/assets/icons/vehicle.svg";
import logo from "../../core/assets/images/logo.png";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = () => {
  let location = useLocation();

  return (
    <SidebarWrapper>
      <img src={logo} alt="" />
      <Menu>
        <MenuItem>
          <GridIcon />
          <div className={"name"}>Общая сводка</div>
        </MenuItem>

        <MenuItem>
          <CartIcon />
          <div className={"name"}>Заказы</div>
        </MenuItem>

        <NavLink to={"/vehicles"}>
          <MenuItem active={location.pathname.includes("vehicles")}>
            <VehicleIcon />
            <div className={"name"}>Транспортные средства</div>
          </MenuItem>
        </NavLink>

        <MenuItem>
          <PhoneIcon />
          <div className={"name"}>Запросы</div>
        </MenuItem>

        <MenuItem>
          <PdfIcon />
          <div className={"name"}>Документы</div>
        </MenuItem>

        <>
          <NavLink to={"/users/"}>
            <MenuItem active={location.pathname.includes("users")}>
              <UserIcon />
              <div className={"name"}>Пользователи</div>
            </MenuItem>
          </NavLink>
        </>

        <MenuItem>
          <LockIcon />
          <div className={"name"}>Доступы</div>
        </MenuItem>
      </Menu>
    </SidebarWrapper>
  );
};

export default memo(Sidebar);
