import React from 'react';
import {HeaderSearch, HeaderWrapper, UserMenu, UserMenuDropdown} from "./styles";
import {ReactComponent as SearchIcon} from "../../core/assets/icons/search.svg";
import {ReactComponent as BellIcon} from "../../core/assets/icons/bell.svg";
import {ReactComponent as Avatar} from "../../core/assets/icons/avatar.svg";
import {ReactComponent as DropdownArrow} from "../../core/assets/icons/arrow-dropdown-filled.svg";
import { useNavigate } from "react-router";

const Header = () => {
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.authorized = "false";
    navigate("/users");
  };

  return (
    <HeaderWrapper>
      <HeaderSearch>
        <SearchIcon/>
        <input type="text"/>
        <BellIcon/>
      </HeaderSearch>
      <UserMenu onClick={logoutHandler}>
        <Avatar/>
        <UserMenuDropdown>
          <div className={"menuDropdown__name"}>Менеджер</div>
          <DropdownArrow/>
        </UserMenuDropdown>
      </UserMenu>
    </HeaderWrapper>
  );
};

export default Header;