import { EStatus, TRequestStatus } from '../types';

export const defaultRequestStatus: TRequestStatus = {
  [EStatus.SUCCESS]: false,
  [EStatus.LOADING]: false,
  [EStatus.ERROR]: false,
}

export const successRequestStatus: TRequestStatus = {
  [EStatus.SUCCESS]: true,
  [EStatus.LOADING]: false,
  [EStatus.ERROR]: false,
}

export const loadingRequestStatus: TRequestStatus = {
  [EStatus.SUCCESS]: false,
  [EStatus.LOADING]: true,
  [EStatus.ERROR]: false,
}

export const errorRequestStatus: TRequestStatus = {
  [EStatus.SUCCESS]: false,
  [EStatus.LOADING]: false,
  [EStatus.ERROR]: true,
}