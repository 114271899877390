import {createAsyncThunk} from '@reduxjs/toolkit';
import {request} from "../core/helpers";
import { TUserFilters, TUser, TUserData } from "../core/types";

type TGetUsersList = { list: TUser[], pagination: TUserFilters };

export const getUsersList = createAsyncThunk<TGetUsersList, TUserFilters>(
  'users/list',
  async (payload) => {
    const response = await request.post('/users/list', payload);

    return response.data;
  }
);

export const getUser = createAsyncThunk<TUser, string>(
  'users/get',
  async (payload) => {
    const response = await request.get(`/users/${payload}`);

    return response.data;
  }
);

export const createUser = createAsyncThunk<TUser, TUserData>(
  'users/create',
  async (payload) => {
    const response = await request.post(`/users/create`, payload);

    return response.data;
  }
);

export const updateUser = createAsyncThunk<TUser, TUserData>(
  'users/update',
  async ({ id, ...payload }) => {
    const response = await request.patch(`/users/${id}`, payload);

    return response.data;
  }
);