import React from "react";
import { ReactComponent as PlusIcon } from "../../../core/assets/icons/plus-button.svg";
import { ReactComponent as DownloadIcon } from "../../../core/assets/icons/download.svg";
import { ReactComponent as ArrowIcon } from "../../../core/assets/icons/arrow-dropdown.svg";
import { ReactComponent as PdfIcon} from "../../../core/assets/icons/pdf.svg";
import { ReactComponent as XlsIcon} from "../../../core/assets/icons/xls.svg";
import { ReactComponent as PrinterIcon} from "../../../core/assets/icons/printer.svg";
import { ReactComponent as CsvIcon} from "../../../core/assets/icons/csv.svg";
import { AddButtonStyled, ButtonEmpty, ButtonExport, ButtonSplitted } from "./styled";

interface IButtonProps {
  onClick?: () => void;
  noIcon?: boolean;
  text?: string;
  width?: string;
}

export const AddButton: React.FC<IButtonProps> = ({ onClick , noIcon, text = "Добавить", width}) => {
  return (
    <AddButtonStyled onClick={onClick} style={width ? {width: width} : {}}>
      {!noIcon && <PlusIcon />}
      {text}
    </AddButtonStyled>
  );
};

export const AddButtonSplitted: React.FC<IButtonProps> = ({ onClick }) => {
  return (
    <ButtonSplitted onClick={onClick}>
      <div className={"buttonText"}>Добавить</div>
      <div className={"buttonIcon"}>
        <PlusIcon />
      </div>
    </ButtonSplitted>
  );
};

interface IExportButtonProps {
  isOpen: boolean;
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExportButton: React.FC<IExportButtonProps> = ({
  isOpen,
  onClick,
}) => {
  return (
    <>
      <ButtonExport onClick={() => onClick(!isOpen)}>
        <DownloadIcon />
        Экспорт
        <ArrowIcon />
        <div className={"dropdown"} style={isOpen ? { display: "flex" } : {}}>
          <div className={"dropdown__item"}>
            <PrinterIcon/>
            Печать
          </div>
          <div className={"dropdown__item"}>
            <XlsIcon/>
            Excel
          </div>
          <div className={"dropdown__item"}>
            <PdfIcon/>
            PDF
          </div>
          <div className={"dropdown__item"}>
            <CsvIcon/>
            CSV
          </div>
        </div>
      </ButtonExport>
    </>
  );
};

interface IEmptyButtonProps {
  onClick: () => void;
}

export const EmptyButton: React.FC<IEmptyButtonProps> = ({onClick}) => {
  return (
    <>
      <ButtonEmpty onClick={onClick}>
        Добавить параметр?
      </ButtonEmpty>
    </>
  )
}
