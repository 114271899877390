import { Space } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FC, ReactElement } from "react";

interface IColumnProps {
  size?: SizeType;
  children: ReactElement | ReactElement[];
}

export const Column: FC<IColumnProps> = ({
  size = 'middle',
  children,
}) => (
  <Space direction="vertical" size={size} style={{ width: '100%' }}>
    {children}
  </Space>
)