import { Table } from "antd";
import { memo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { pageSizeOptions, tableLocale } from "../../../core/constants/table";
import { getUsersList } from "../../../services/users";
import { setFilters } from "../../../storage/models/users";
import { useAppDispatch, useAppSelector } from "../../../storage/store";
import { TableData } from "../../tableComponent";
import { columns } from "./columns";


const UsersTable  = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const users = useAppSelector((state) => state.users.list);
  const filters = useAppSelector((state) => state.users.filters);


  const handleChangePagination = useCallback(
    (page: number, pageSize: number) => {      
      dispatch(setFilters({ ...filters, page, pageSize }));
    },
    [dispatch, filters],
  );

  useEffect(
    () => {
      dispatch(getUsersList(filters));
    },
    [dispatch, filters],
  );

  return (
    <TableData
      rowKey="id"
      dataSource={users}
      columns={columns(navigate)}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions,
        locale: tableLocale,
        pageSize: filters.pageSize,
        current: filters.page,
        total: filters.total,
        onChange: handleChangePagination,
      }}
    />
  )
}

export default memo(UsersTable);