import React, { memo, useState } from "react";
import { PageWrapper } from "../../components/pageWrapper/styles";
import { PageHeader } from "../../components/pageHeader/styles";
import { AddButton, ExportButton } from "../../components/@UI/Button/Button";
import  VehiclesTable  from "../../components/tables/VehiclesTable/VehicleTable";
import { useNavigate, useParams } from "react-router";
import { EPages } from "../../core/types";
import ModalVehicle from "../../components/modalVehicle";


const VehiclesPage: React.FC = () => {
  const { vehicleId } = useParams();
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  return (
    <>
      <PageWrapper>
        <PageHeader>
          <div className={"pageTitle"}>Транспортные средства</div>
          <div className={"buttons"}>
            <ExportButton isOpen={dropdownOpen} onClick={setDropdownOpen} />
            <AddButton onClick={() => navigate(`${EPages.VEHICLES_PAGE}/create`)} />
          </div>
        </PageHeader>
        <VehiclesTable/>
      </PageWrapper>
      {vehicleId && <ModalVehicle />}
    </>
  );
};

export default memo(VehiclesPage);