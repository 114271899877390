import { Input, Typography } from "antd"
import { BaseType } from "antd/es/typography/Base";
import { InputHTMLAttributes } from "react"
import { Column } from "../Column";

interface IPasswordInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'>{
  type?: BaseType;
  label: string;
}

export const PasswordInput = ({
  type,
  label,
  ...props
}: IPasswordInputProps) => {
  return (
    <Column size="small">
      <Typography.Text type={type}>{label}</Typography.Text>
      <Input.Password {...props} size="large" />
    </Column>
  )
}