import * as yup from "yup";

export enum EVehicleFormFields {
  NAME = 'name',
  DISPLAY_NAME = 'displayName',
  ID = 'id',

}

export const defaultValues = {
  [EVehicleFormFields.NAME]: '',
  [EVehicleFormFields.DISPLAY_NAME]: '',
  [EVehicleFormFields.ID]: '',
}

export const schema = yup.object().shape({
  [EVehicleFormFields.NAME]: yup.string().required(),
  [EVehicleFormFields.DISPLAY_NAME]: yup.string().min(2).max(100).required(),
  [EVehicleFormFields.ID]: yup.string().required(),
}).required();