import React, { memo, useEffect } from "react"
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { login } from "../../../services/auth"
import { defaultValues, ELoginFormFields, schema } from "./schema";
import { TLoginData } from "../../../core/types";
import { useAppDispatch, useAppSelector } from "../../../storage/store";
import { useNavigate } from "react-router";
import { Column } from "../../@UI/Column";
import { PasswordInput, TextInput } from "../../@UI/Input";
import { Button } from "../../@UI/Button";

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.auth.profile);

  const { handleSubmit, control } = useForm<TLoginData>({
    defaultValues,
    resolver: yupResolver(schema)
  });

  const handleSubmitForm = async (data: TLoginData) => {    
    dispatch(login(data));
  }

  useEffect(() => {
    if (!profile) {
      localStorage.clear();
    }

    if (profile) {
      navigate('/users');
    }
  }, [navigate, profile])

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Column>
        <Controller
          name={ELoginFormFields.EMAIL}
          control={control}
          render={({ field }) => <TextInput {...field} label="Логин" />}
        />
        <Controller
          name={ELoginFormFields.PASSWORD}
          control={control}
          render={({ field }) => <PasswordInput {...field} label="Пароль" />}
        />
        <Button type="submit" style={{ marginTop: 20 }}>Войти</Button>
      </Column>
    </form>
  )
}

export default memo(LoginForm);