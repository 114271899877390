import { createSlice } from '@reduxjs/toolkit';
import {
  defaultRequestStatus,
  successRequestStatus,
  loadingRequestStatus,
  errorRequestStatus,
} from '../../core/constants/common';
import { login, getProfile } from '../../services/auth';
import { TRequestStatus, TUser } from '../../core/types';

interface IInitialState {
    status: TRequestStatus;
    profile: TUser | null;
}

const initialState: IInitialState = {  
    status: defaultRequestStatus,
    profile: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(login.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.status = successRequestStatus;
      });
      builder.addCase(login.pending, (state) => {
        state.status = loadingRequestStatus;
      });
      builder.addCase(login.rejected, (state) => {
        state.profile = null;
        state.status = errorRequestStatus;
      });
      builder.addCase(getProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.status = successRequestStatus;
      });
      builder.addCase(getProfile.pending, (state) => {
        state.status = loadingRequestStatus;
      });
      builder.addCase(getProfile.rejected, (state) => {
        state.profile = null;
        state.status = errorRequestStatus;
      });
    }
  });

export default authSlice.reducer;