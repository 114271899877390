import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorRequestStatus, loadingRequestStatus, successRequestStatus } from '../../core/constants/common';
import { TRequestStatus, TUser, TUserFilters } from '../../core/types';
import { createUser, getUser, getUsersList, updateUser } from '../../services/users';

interface IInitialState {
    status: TRequestStatus;
    list: TUser[];
    filters: TUserFilters;
    target: TUser | null;
}

const initialState: IInitialState = {  
    status: {
      loading: false,
      success: true,
      error: false,
    },
    filters: {
      page: 1,
      pageSize: 5,
      total: 0,
      search: '',
      roles: [],
    },
    list: [],
    target: null,
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
      setFilters: (state, action: PayloadAction<Partial<TUserFilters>>) => {
        state.filters = { ...state.filters, ...action.payload }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getUsersList.fulfilled, (state, action) => {
        state.list = action.payload.list;
        state.filters.total = action.payload.pagination.total;
        state.status = successRequestStatus;
      });
      builder.addCase(getUsersList.pending, (state) => {
        state.status = loadingRequestStatus;
      });
      builder.addCase(getUsersList.rejected, (state) => {
        state.status = errorRequestStatus;
      });
      builder.addCase(getUser.fulfilled, (state, action) => {
        state.target = action.payload;
        state.status = successRequestStatus;
      });
      builder.addCase(getUser.pending, (state) => {
        state.status = loadingRequestStatus;
      });
      builder.addCase(getUser.rejected, (state) => {
        state.target = null;
        state.status = errorRequestStatus;
      });
      builder.addCase(createUser.fulfilled, (state) => {
        state.status = successRequestStatus;
        state.filters = { ...state.filters };
        state.target = null;
      });
      builder.addCase(createUser.pending, (state) => {
        state.status = loadingRequestStatus;
      });
      builder.addCase(createUser.rejected, (state) => {
        state.status = errorRequestStatus;
      });
      builder.addCase(updateUser.fulfilled, (state) => {
        state.status = successRequestStatus;
        state.filters = { ...state.filters };
        state.target = null;
      });
      builder.addCase(updateUser.pending, (state) => {
        state.status = loadingRequestStatus;
      });
      builder.addCase(updateUser.rejected, (state) => {
        state.status = errorRequestStatus;
      });
    }
  });


export const { setFilters } = usersSlice.actions;

export default usersSlice.reducer;