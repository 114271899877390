import { yupResolver } from "@hookform/resolvers/yup";
import { memo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { EPages, EUserRoles, TVehicleData } from "../../../core/types";
import { createVehicle, updateVehicle } from "../../../services/vehicle";
import { useAppDispatch, useAppSelector } from "../../../storage/store";
import { Button } from "../../@UI/Button";
import { Column } from "../../@UI/Column";
import { TextInput } from "../../@UI/Input";
import { Row } from "../../@UI/Row";
import { EVehicleFormFields, schema } from "./schema";



const VehicleForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const vehicle = useAppSelector((state) => state.vehicles.target);
  const isCreate = !vehicle?.id;

  const { handleSubmit, control, reset } = useForm<TVehicleData>({
    resolver: yupResolver(schema)
  });

  const handleSubmitForm = async (data: TVehicleData) => {
    if (isCreate) {
      await dispatch(createVehicle(data));
    } else {
      await dispatch(updateVehicle({ id: vehicle?.id, ...data }));
    }

    navigate(EPages.VEHICLES_PAGE)
  }

  useEffect(
    () => {
      if (!isCreate) {
        reset(vehicle);
      }
    },
    [isCreate, reset, vehicle],
  );
  
  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Column>
        <Row>
          <Controller
            name={EVehicleFormFields.ID}
            control={control}
            render={({ field }) => <TextInput {...field} label={"ID"} />}
          />
        </Row>
        <Row>
          <Controller
            name={EVehicleFormFields.NAME}
            control={control}
            render={({ field }) => <TextInput {...field} label={"Имя"} />}
          />
        </Row>
        <Row>
          <Controller
            name={EVehicleFormFields.DISPLAY_NAME}
            control={control}
            render={({ field }) => <TextInput {...field} label={"Display Name"} />}
          />
        </Row>
        <Button type="submit" style={{ marginTop: 20 }}>Сохранить</Button>
      </Column>
    </form>
  )
}

export default memo(VehicleForm);