import { SearchOutlined, DownOutlined, SlidersOutlined } from "@ant-design/icons"
import { ChangeEvent } from "react"
import { EUserRoles, TUserRolesKeys } from "../../../core/types"
import { setFilters } from "../../../storage/models/users"
import { useAppDispatch, useAppSelector } from "../../../storage/store"
import { CommonInput } from "../../@UI/Input"
import { CommonSelect } from "../../@UI/Input"
import { TableFiltersContainer } from "./styled"

const userRolesOptions = Object.entries(EUserRoles).map(([value, label]) => ({
  label,
  value,
}));

export const TableFilter = () => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state) => state.users.filters);
  const search = useAppSelector((state) => state.users.filters.search);
  const roles = useAppSelector((state) => state.users.filters.roles);

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilters({ ...filters, search: event.target.value }))
  };  

  const handleChangeRoles = (values: TUserRolesKeys[]) => {
    dispatch(setFilters({ ...filters, roles: values }))
  };  

  return (
    <TableFiltersContainer>
      <CommonInput
        placeholder="Введите параметры поиска"
        value={search}
        onChange={handleChangeSearch}
        prefix={<SearchOutlined />}
        suffix={<SlidersOutlined />}
      />
      <CommonSelect
        value={roles}
        options={userRolesOptions}
        size="large"
        // onChange={handleChangeRoles}
        placeholder='Действия'
        maxTagCount={2}
        showSearch={false}
        allowClear
      />
    </TableFiltersContainer>
  )
}