import * as yup from "yup";

export enum EUsersFormFields {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  SECOND_NAME = 'secondName',
  PHONE_NUMBER = 'phoneNumber',
  CITY = 'city',
  ROLE = 'role',
}

export const defaultValues = {
  [EUsersFormFields.EMAIL]: '',
  [EUsersFormFields.FIRST_NAME]: '',
  [EUsersFormFields.SECOND_NAME]: '',
  [EUsersFormFields.PHONE_NUMBER]: '',
  [EUsersFormFields.CITY]: '',
  [EUsersFormFields.ROLE]: '',
}

export const schema = yup.object().shape({
  [EUsersFormFields.EMAIL]: yup.string().email().required(),
  [EUsersFormFields.FIRST_NAME]: yup.string().min(2).max(100).required(),
  [EUsersFormFields.SECOND_NAME]: yup.string().min(2).max(100).required(),
  [EUsersFormFields.PHONE_NUMBER]: yup.string().min(6).max(14).required(),
  [EUsersFormFields.CITY]: yup.string().min(2).max(50).required(),
  [EUsersFormFields.ROLE]: yup.string().required(),
}).required();