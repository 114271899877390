import React, { useCallback, useEffect } from "react";
import {
  ModalTitle,
  ModalWindow,
  ModalWrapper,
} from "./styles";
import { UsersForm } from "../forms/UsersForm";
import { Button, Typography } from "antd";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../../storage/store";
import { getUser } from "../../services/users";
import { EPages } from "../../core/types";
import { CloseCircleOutlined } from "@ant-design/icons";

const MODAL_FLOOR_ID = 'modal-floor';

const Modal = () => {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isCreate = userId === 'create';

  const initUserDate = useCallback(async () => {
    if (userId && !isCreate) {
      await dispatch(getUser(userId));
    }
  }, [dispatch, isCreate, userId]);

  const handleCloseModal = (event: any) => {
    if (event.target.id === MODAL_FLOOR_ID) {
      navigate(EPages.USERS_PAGE)
    }
  }

  useEffect(() => {
    initUserDate();
  }, [initUserDate])

  return (
    <ModalWrapper id={MODAL_FLOOR_ID} onClick={handleCloseModal}>
      <ModalWindow>
        <ModalTitle>
          <Typography.Title level={3}>
            {`${userId === 'create' ? 'Добавление' : 'Редактирование'} пользователя`}
          </Typography.Title>
          <Button
            type="primary"
            icon={<CloseCircleOutlined />}
            onClick={() => navigate(EPages.USERS_PAGE)}
          />
        </ModalTitle>
        <UsersForm />
      </ModalWindow>
    </ModalWrapper>
  );
};

export default Modal;
