import styled from "styled-components";
import { 
    Typography, 
    Table, 
    Tag,
    Button,
    Badge, 
    TableProps
} from 'antd';


const { Text } = Typography;


export const TableWrapper = styled.div`
    background-color: #ffffff;
    padding: 32px 30px 30px;
    object-fit: contain;
    border-radius: 20px;
`;
export const TableData = styled(Table)<TableProps<any>>` 

`;

export const TableElement = styled(Text)<{ isPrimary?: boolean }>`
    color: ${({ isPrimary }) => isPrimary ? '#3f434a' : '#8a9099'};
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
`;


export const TableTag = styled(Tag)`
    padding: 5px 15px;
    font-size: 12px;
    color: ${(props)=> props.color}; 
    color: #20a144;
    border-radius: 9px;
    border: none;
`;
export const TableButton = styled(Button)`
    background: inherit;
    color: #3f434a;
    border: none;
    box-shadow: none;
    &:hover {
        background-color: #105b72c2;
        }
`;

export const TableBadge = styled(Badge)`
    margin-right: 60px;

    .ant-scroll-number{
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eeeeee;
        color: #8a9099;
        border-radius: 7px;
        font-size: 10px;
        margin-top: 9px; 
        right: -20px;
       
    }
`
export const BadgeWrapper = styled.div`
    width:100%;
    margin-bottom: 30px;
    padding: 15px 0;
    border-bottom: 1px solid #eeeeee;
`