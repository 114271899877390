import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { NavigateFunction } from "react-router";
import { EPages, EUserRoles, TUser } from "../../../core/types";
import { TableButton, TableElement, TableTag } from "../../tableComponent/styled";

export const columns = <TData extends Record<string,string>>(navigate: NavigateFunction): ColumnsType<TData> => [

  {
    title:() => <TableElement>ID</TableElement>,
    dataIndex: 'id',
    key: 'id',
    render: (text) => <TableElement >{text}</TableElement>
  },
  {
    title:() => <TableElement>ИМЯ</TableElement>,
    dataIndex: 'firstName', 
    key: 'firstName',
    render: (text) => <TableElement isPrimary >{text}</TableElement>
  },
  {
    title: () => <TableElement>ФАМИЛИЯ</TableElement>,
    dataIndex: 'secondName',
    key: 'secondName',
    render: (text) => <TableElement isPrimary>{text}</TableElement>
  },
  {
    title: () => <TableElement >ТЕЛЕФОН</TableElement>,
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    render: (text) =><TableElement >{text}</TableElement>
  },
  {
    title: () => <TableElement>КОМИССИЯ</TableElement>,
    dataIndex: 'commission',
    key: 'commission',
    render: () =><TableElement isPrimary>12%</TableElement>
  },
  {
    title: () => <TableElement>ГОРОД</TableElement>,
    dataIndex: 'city',
    key: 'city',
    render: (text) => <TableElement isPrimary>{text}</TableElement>
  },
  {
    title: () => <TableElement>ПОЧТА</TableElement>,
    dataIndex: 'email',
    key: 'email',
    render: (text) => <TableElement isPrimary>{text}</TableElement>
  },
  {
    title: () => <TableElement>РОЛЬ</TableElement>,
    dataIndex: 'role',
    key: 'role',
    render: (role: keyof typeof EUserRoles) => EUserRoles[role],
  },
  {
    title: () => <TableElement>СТАТУС</TableElement>,
    dataIndex:'tag',
    key: 'tag',
    render: () => (
      <TableTag color={'success'}>Все ок</TableTag>
    ) 
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (id: string) => (
      <TableButton
        className="btn-class"
        type="primary"
        icon={<MoreOutlined />}
        onClick={() => navigate(`${EPages.USERS_PAGE}/${id}`)}
      />
    ),
  },

];