import styled from "styled-components";

export const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  
  .pageTitle {
    font-size: 28px;
    line-height: 33px;
    font-weight: 500;
    color: #3F434A;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 270px;
  }
  
  a { 
    text-decoration: none;
  }
  
  .backButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 81px;
    height: 28px;
    background: #edeeef;
    border-radius: 8px;
    color: #3F434A;
    font-size: 12px;
    padding: 6px 12px;
    cursor: pointer;
    margin-right: 8px;
  }
`;