import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  width: 100%;
  height: 65px;
  border-bottom: 1px solid #E8E9EB;
  padding: 13px 28px;
`;

export const HeaderSearch = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 31px;
  border-right: 1px solid #E8E9EB;
  padding-right: 23px;
  
  input {
    border: none;
    outline: none;
    color: #3F434A;
    font-size: 16px;
    width: 100%;
    margin: 0 15px;
    height: 100%;
  }
`;

export const UserMenu = styled.div`
  display: flex;
  padding-left: 24px;
  align-items: center;
  width: 200px;
`;

export const UserMenuDropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  
  .menuDropdown__name {
    font-size: 15px;
    line-height: 18px;
    color: #3F434A;
    margin: 0 2px 0 12px;
  }
`;