import React, { useCallback, useEffect } from "react";
import {
  ModalTitle,
  ModalWindow,
  ModalWrapper,
} from "./styles";
import VehicleForm from "../forms/VehicleForm/VehicleForm";
import { Button, Typography } from "antd";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../../storage/store";
import { EPages } from "../../core/types";
import { CloseCircleOutlined } from "@ant-design/icons";
import { getVehicle } from "../../services/vehicle";

const MODAL_FLOOR_ID = 'modal-floor';

const ModalVehicle = () => {
  const { vehicleId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isCreate = vehicleId === 'create';

  const initVehicleDate = useCallback(async () => {
    if (vehicleId && !isCreate) {
      await dispatch(getVehicle(vehicleId));
    }
  }, [dispatch, isCreate, vehicleId]);

  const handleCloseModal = (event: any) => {
    if (event.target.id === MODAL_FLOOR_ID) {
      navigate(EPages.VEHICLES_PAGE)
    }
  }

  useEffect(() => {
    initVehicleDate();
  }, [initVehicleDate])

  return (
    <ModalWrapper id={MODAL_FLOOR_ID} onClick={handleCloseModal}>
      <ModalWindow>
        <ModalTitle>
          <Typography.Title level={3}>
            {`${vehicleId === 'create' ? 'Добавление' : 'Редактирование'} пользователя`}
          </Typography.Title>
          <Button
            type="primary"
            icon={<CloseCircleOutlined />}
            onClick={() => navigate(EPages.VEHICLES_PAGE)}
          />
        </ModalTitle>
        <VehicleForm />
      </ModalWindow>
    </ModalWrapper>
  );
};

export default ModalVehicle;
